import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    routes: [
        { path: '/'                     , name: 'home'                  , component: () => import( './pages/Login.vue'                      ) },
        { path: '/dashboard'            , name: 'dashboard'             , component: () => import( './pages/Dashboard.vue'                  ) },
        { path: '/categorii-drepturi'   , name: 'categorii-drepturi'    , component: () => import ('./pages/categorii-drepturi/Categorii-drepturi_list.vue'     ) },
        { path: '/drepturi'             , name: 'drepturi'              , component: () => import ('./pages/drepturi/Drepturi_list.vue'                         ) },
        { path: '/grupuri-utilizatori'  , name: 'grupuri-utilizatori'   , component: () => import ('./pages/grupuri-utilizatori/Grupuri-utilizatori_list.vue'   ) },
        { path: '/utilizatori'          , name: 'utilizatori'           , component: () => import ('./pages/utilizatori/Utilizatori_list.vue'                   ) },

        { path: '/clubstables'  , name: 'clubstables', component: () => import( './pages/clubstables/Clubstables_list.vue') },
        { path: '/clubs'        , name: 'clubs', component: () => import( './pages/clubs/Clubs_list.vue') },

        { path: '/atmosphere_models', name: 'atmosphere_models', component: () => import( './pages/atmosphere_models/Atmosphere_models_list.vue') },
        { path: '/guns_and_tanks', name: 'guns_and_tanks', component: () => import( './pages/guns_and_tanks/GunsAndTanks_list.vue') },

        { path: '/limos', name: 'limos', component: () => import( './pages/limos/Limos_list.vue') },
        { path: '/helicopters', name: 'helicopers', component: () => import( './pages/helicopters/Helicopters_list.vue') },


        { path: '/countries', name: 'countries', component: () => import( './pages/countries/Countries_list.vue') },
        { path: '/creditcard', name: 'creditcard', component: () => import( './pages/creditcard/Creditcard_list.vue') },
        { path: '/hosts', name: 'hosts', component: () => import( './pages/hosts/Hosts_list.vue') },
        { path: '/reservations', name: 'reservations', component: () => import( './pages/reservations/Reservations_list.vue') },
        { path: '/users', name: 'users', component: () => import( './pages/users/Users_list.vue') },

        { path: '/viphosts', name: 'viphosts', component: () => import( './pages/viphosts/Viphosts_list.vue') },
        { path: '/vipbodyguards', name: 'vipbodyguards', component: () => import( './pages/vipbodyguards/Vipbodyguards_list.vue') },

        { path: '/vouchers', name: 'vouchers', component: () => import( './pages/vouchers/Vouchers_list.vue') },

        { path: '/vouchers_report', name: 'vouchers_report', component: () => import( './pages/reports/VouchersReport.vue') },
    ]
})
